
var query = document.querySelector.bind(document)
var queryAll = document.querySelectorAll.bind(document)
var fromId = document.getElementById.bind(document)
var fromClass = document.getElementsByClassName.bind(document)
var fromTag = document.getElementsByTagName.bind(document)

document.addEventListener("DOMContentLoaded", function() {

    var mySwiper = new Swiper ('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
      },
  
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    // Change caption to current slide
    mySwiper.on('slideChange', function () {
      var current_slide = this.activeIndex
      current_slide = queryAll('.swiper-slide')[current_slide]
      var caption = current_slide.querySelector('.caption')
      query('.slider-nav strong').textContent = caption.textContent    
    })
    
    // Populate first caption
    var first_slide = queryAll('.swiper-slide')[1]
    var first_caption = first_slide.querySelector('.caption').textContent
    query('.slider-nav strong').textContent = first_caption


    var selects = queryAll('select')
    selects.forEach((select) => {
      new Dropkick(select,{
        mobile: true
      });
    });

    var modal = query('#register')

    function openModal(){
      modal.style.opacity = 0
      modal.style.display = 'flex'
      window.setTimeout(function(){
        modal.style.opacity = 1
        modal.style.transform = 'translateY(0)'        
      }, 1)
    }

    function closeModal(){
      modal.style.opacity = 0
      modal.style.transform = 'translateY(-100%)'      
      window.setTimeout(function(){
        modal.style.display = 'none'
      }, 350)
    } 

    query('#register .close').addEventListener('click', closeModal)
  
    var register_buttons = queryAll('[data-open-register]')
    register_buttons.forEach((button) => {
      button.addEventListener('click', openModal)
    });

    var sections = queryAll('.parallax-wrap > section');

    sections.forEach(function(el, index, array){    
        var callback = function(entries, observer) { 
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              //animateIn(entry.target)
            } else {
              //animateOut(entry.target)
            }
          });
        };
        // Create Observer
        var observer = new IntersectionObserver(callback, {threshold: 0.5});
        observer.observe(el);
    });


    var animateIn = (el) => {
      var id = el.getAttribute('id');
      switch(id){
        case "intro":
      
          anime({
            targets: query('#intro h1'),
            translateX: ['-100%', '0%'],
            duration: 800
          });

          break;
        case "about":

            anime({
              targets: query('#about .image-copy--copy > div'),
              translateX: ['100%', '0%'],
              duration: 800
            });        

          break;
      }
      console.log('Animate In' + id);
    }

    var animateOut = (el) => {
      var id = el.getAttribute('id');
      console.log('Animate Out' + id);
    }


    var sendForm = () => {
      var form = query("#register form");
      var data = new FormData(form);

      axios.post('/inc/process.php', data)
      .then(function (response) {
        console.log(response);
        thankYou();
      })
      .catch(function (error) {
        console.log(error);
      });      
    }

    var thankYou = () => {
      var form = query("#register form");
      var thankYou = query("#thank-you");

      form.style.opacity = 0
      window.setTimeout(function(){
        form.style.display = 'none'
        thankYou.style.display = 'block';
      }, 350)
      window.setTimeout(function(){
        thankYou.style.opacity = 1;
      }, 375)

      window.setTimeout(closeModal, 6500);      

    }

    query('#register form').addEventListener('submit', function(e){
      e.preventDefault();
      sendForm();
    });







 })